import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCrmCall } from '../../../../api/useCrmCall';
import { UserContext } from '../../../../context/UserContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import { TransactionItem } from '../../../molecules/TransactionItem/TransactionItem';
import styles from './Transactions.module.css';

export const Transactions = () => {
  const {
    userState: { user },
  } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const [activeTab, setActiveTab] = useState('all');
  const { t } = useTranslation('Transactions');
  const { postCrmRequest } = useCrmCall();

  const { data: history, error } = useQuery({
    queryKey: ['transactionsHistory', user?.userId],
    queryFn: () => postCrmRequest('/payment/history', user?.userId),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  const buttons = [
    {
      name: 'Всё',
      content: 'all',
    },
    {
      name: 'Депозиты',
      content: 'payin',
    },
    {
      name: 'Выводы',
      content: 'payout',
    },
  ];

  const handleClick = name => {
    setActiveTab(name);
  };

  const filteredHistory = activeTab === 'all' ? history : history?.filter(itm => itm?.type === activeTab);

  return (
    <div className={styles.transactions}>
      <ContentSwitcher active={activeTab} buttonsMap={buttons} handleClick={handleClick} />
      {filteredHistory?.length === 0 ? (
        <p className={styles.notFound}>{t('noFound')}</p>
      ) : (
        filteredHistory?.map(item => <TransactionItem key={item?.date} item={item} />)
      )}
    </div>
  );
};
