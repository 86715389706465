import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

import { useCrmCall } from '../../../../api/useCrmCall';
import { UserContext } from '../../../../context/UserContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { GameItem } from './GameItem';
import styles from './GameList.module.css';

export const GameList = () => {
  const {
    userState: { user },
  } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();

  const userId = user?.userId || null;
  const email = user?.email || null;

  const { data: games, error } = useQuery({
    queryKey: ['recommendedGames', userId, email],
    queryFn: () =>
      postCrmRequest('/games/recommended_games', {
        lang: 'ru',
        userId,
        email,
      }),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  return (
    <div className={styles.gameList}>
      {games?.map(game => (
        <GameItem key={game.id} game={game} />
      ))}
    </div>
  );
};
