import { useMutation } from '@tanstack/react-query';
import { useContext, useState } from 'react';

import { useCrmCall } from '../../../../api/useCrmCall';
import { UserContext } from '../../../../context/UserContext';
import { ModalContext } from '../../../../context/ModalContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { Button } from '../../../atoms/Button/Button';
import { CardSelect } from '../../../molecules/CardSelect/CardSelect';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';

export const WithdrawalRequisites = ({ changeModalContent }) => {
  const [activeCard, setActiveCard] = useState();
  const { userState } = useContext(UserContext);
  const { modalState } = useContext(ModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();

  const initWithdrawalMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_init', data),
    onError: error => setNotifications({ type: 'error', message: error }),
  });

  const data = {
    userId: userState?.user?.userId,
    cardId: activeCard?.id,
    email: userState?.user?.email,
    amount: modalState?.amount,
  };

  return (
    <>
      <CardSelect activeCard={activeCard} setActiveCard={setActiveCard} changeModalContent={changeModalContent} />
      <NumberInput labelText='Сумма вывода' name='amount' disabled={true} value={modalState?.amount || ''} />
      <Button
        buttonText={'Вывод средств'}
        isLoading={initWithdrawalMutation?.isPending}
        onClick={() => initWithdrawalMutation?.mutate(data)}
      />
    </>
  );
};
