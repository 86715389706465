import { createContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    // Загрузка темы из localStorage или использование темы по умолчанию 'system'
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'system';
  });

  useEffect(() => {
    const systemThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleSystemThemeChange = () => {
      const systemTheme = systemThemeQuery.matches ? 'dark' : 'light';
      const appliedTheme = theme === 'system' ? systemTheme : theme;
      document.documentElement.dataset.theme = appliedTheme;
    };

    handleSystemThemeChange(); // Инициализация темы при монтировании компонента
    systemThemeQuery.addListener(handleSystemThemeChange); // Слушатель для изменений системной темы

    return () => {
      systemThemeQuery.removeListener(handleSystemThemeChange); // Удаление слушателя при размонтировании
    };
  }, [theme]);

  useEffect(() => {
    // Сохранение выбранной темы в localStorage
    localStorage.setItem('theme', theme);
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeProvider };
