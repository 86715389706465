import { useNavigate } from 'react-router-dom';

import gameIconExample from './gameIconExample.png';
import styles from './GameItem.module.css';

export const GameItem = ({ game }) => {
  const navigate = useNavigate();

  const handleGameClick = () => {
    navigate(`/game/${game?.id}`, { state: { game } });
  };
  return (
    <div onClick={handleGameClick} className={styles.gameIco}>
      <img src={gameIconExample} alt={game?.name} />
    </div>
  );
};
