import { useContext } from 'react';

import { ModalContext } from '../context/ModalContext';
import { UserContext } from '../context/UserContext';

export const useGetModalSubmitData = pageFrom => {
  const { userState } = useContext(UserContext);
  const { modalState } = useContext(ModalContext);

  const submitDataMap = {
    withdrawal: {
      userId: userState?.user?.userId,
      reqType: modalState?.paymentMethod,
      requisite: {
        reqNum: modalState?.requisiteNumber,
        comment: modalState?.bankName,
      },
      client: { email: userState?.user?.email },
      code: '123456',
    },
  };

  return submitDataMap[pageFrom];
};
