import { useContext, useEffect } from 'react';

import { verboxLoader } from '../verboxLoader';
import { Notifications } from './components/organisms/Notificatiions/Notifications';
import { UserContext } from './context/UserContext';
import Routes from './router';

const initVerbox = userState => {
  if (userState?.isAuthenticated) {
    verboxLoader({
      VerboxSetup: { clientId: userState?.user?.userId },
      clientInfo: {
        clientId: userState?.user?.userId,
        phone: userState?.user?.phone,
        name: userState?.user?.username,
        custom: {
          crm_id: userState?.crmUserData?.id,
        },
      },
    });
  } else {
    verboxLoader({
      VerboxSetup: { clientId: userState?.user?.userId },
    });
  }
};

export const App = () => {
  const { userState } = useContext(UserContext);

  useEffect(() => {
    initVerbox(userState);
  }, [userState]);

  return (
    <>
      <Notifications />
      <Routes />
    </>
  );
};
