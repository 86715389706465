import DOMPurify from 'dompurify';
import { useContext } from 'react';

import { BonusesModalContext } from '../../../../../context/BonusesModalContext';
import { UserContext } from '../../../../../context/UserContext';
import { prepareNewsImageString } from '../../../../../utils/prepareNewsImageString';
import styles from './BonusItemModal.module.css';
import { getButton } from './getButton';

export const BonusItemModal = () => {
  const {
    userState: { user },
  } = useContext(UserContext);
  const { clickedBonus, activateBonusMutation, deactivateBonusMutation } = useContext(BonusesModalContext);
  const img = prepareNewsImageString(clickedBonus?.promo?.image, import.meta.env.VITE_STATIC_DOMAIN);

  const handleClick = bonusId => {
    if (clickedBonus?.status === 'ALLOW') return activateBonusMutation.mutate({ userId: user?.userId, bonusId });

    if (clickedBonus?.status === 'IN_PROGRESS') return deactivateBonusMutation.mutate({ userId: user?.userId });
  };

  return (
    <>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div className={styles.params}>
        <div className={styles.paramsItem}>
          <span className={styles.paramsTitle}>Цель:</span>
          <span className={styles.paramsValue}>х5</span>
        </div>
        <div className={styles.divider} />
        <div className={styles.paramsItem}>
          <span className={styles.paramsTitle}>Минимальный депозит:</span>
          <span className={styles.paramsValue}>2000 ₽</span>
        </div>
        <div className={styles.divider} />
        <div className={styles.paramsItem}>
          <span className={styles.paramsTitle}>Размер бонуса:</span>
          <span className={styles.paramsValue}>100% + 100FS</span>
        </div>
      </div>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(clickedBonus?.promo?.ru?.full_text),
        }}
      />
      {getButton(clickedBonus, handleClick)}
    </>
  );
};
