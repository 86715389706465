import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { UserContext } from '../../../context/UserContext';
import { GamesModalContext } from '../../../context/GamesModalContext';
import { NotificationsContext } from '../../../context/NotificationsContext';
import styles from './GamePage.module.css';

export const GamePage = () => {
  const { state } = useLocation();
  const { games } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const {
    userState: { user },
  } = useContext(UserContext);
  const { postCrmRequest } = useCrmCall();

  const navigate = useNavigate();

  const game = games?.find(item => item?.id === state?.id);

  const userId = user?.userId || null;

  useEffect(() => {
    if (!userId) navigate('/login');
  }, [userId]);

  const {
    data: gameUrl = '',
    error,
    isLoading,
  } = useQuery({
    queryKey: ['gameSession', userId, game?.key],
    queryFn: () => postCrmRequest('/games/game_session', { userId, gameKey: game?.key }),
    enabled: Boolean(userId),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <div className={styles.gamePage}>
      <iframe src={gameUrl} title={game?.key} width='100%' height='100%' frameBorder='0' allowFullScreen />
    </div>
  );
};
