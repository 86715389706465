import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';

import { useCrmCall } from '../../../../api/useCrmCall';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { UserContext } from '../../../../context/UserContext';
import { InputWithButton } from '../../../molecules/InputWithButton/InputWithButton';
import styles from './Settings.module.css';
import { ThemeSwitcher } from './ui/ThemeSwitcher';
import { getEmailButtonName, getPhoneButtonName } from './utils/getButtonName';
import { getEmailUrl, getPhoneUrl } from './utils/getModalUrl';

export const Settings = ({ changeModalContent }, ...props) => {
  const [birthDate, setBirthDate] = useState(null);
  const { userState } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();

  const setBirthdateMutation = useMutation({
    mutationFn: date => postCrmRequest('/user/set_birthdate', { userId: userState?.user?.userId, birthDate: date }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['userData'] }),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const mockIsEmailExist = true;
  const mockIsPhoneVerified = false;

  const handleEmailButtonClick = useCallback(() => {
    changeModalContent(getEmailUrl(mockIsEmailExist), `${getEmailButtonName(mockIsEmailExist)} почту`);
  }, [mockIsEmailExist, changeModalContent]);

  const handlePhoneButtonClick = useCallback(() => {
    changeModalContent(getPhoneUrl(mockIsPhoneVerified), `${getPhoneButtonName(mockIsPhoneVerified)} номер`);
  }, [mockIsPhoneVerified, changeModalContent]);

  const handleChangeBirthdate = useCallback(e => {
    setBirthDate(e.target.value);
  }, []);

  const client = userState?.crmUserData;

  return (
    <div className={styles.settings}>
      <ThemeSwitcher />
      <div className={styles.data}>
        <InputWithButton
          isVerified={userState?.user?.isEmailVerified}
          placeholder='usermail@gmail.com'
          labelText='E-mail'
          // buttonText={getEmailButtonName(mockIsEmailExist)}
          name='email'
          type='email'
          // onButtonClick={handleEmailButtonClick}
          value={userState?.user?.email}
          disabled={true}
        />
        <InputWithButton
          isVerified={userState?.user?.isPhoneVerified}
          placeholder='Добавить номер'
          labelText='Номер Телефона'
          // buttonText={getPhoneButtonName(mockIsPhoneVerified)}
          name='phone'
          // onButtonClick={handlePhoneButtonClick}
          masked
          mask='+7 (000) 000 00 00'
          value={userState?.user?.phone}
          disabled={true}
        />
        <InputWithButton
          labelText='Пароль'
          placeholder='Введите новый пароль'
          buttonText='Сменить'
          name='password'
          type='password'
        />
        <InputWithButton
          masked
          mask='00.00.0000'
          placeholder='дд.мм.гггг'
          labelText='Дата Рождения'
          buttonText={client?.dates?.length ? null : 'Добавить'}
          name='dateOfBirth'
          type='date'
          value={client?.dates?.[0]?.date || birthDate}
          onChange={handleChangeBirthdate}
          onButtonClick={() => setBirthdateMutation.mutate(birthDate)}
          disabled={client?.dates?.length}
        />
      </div>
    </div>
  );
};
