import { useQuery } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../api/useCrmCall';
import { NotificationsContext } from './NotificationsContext';

const UserContext = createContext();

const getIsAuthenticated = () => {
  const token = localStorage.getItem('authToken');

  return !(!token || token === 'undefined');
};

const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState({
    isAuthenticated: getIsAuthenticated(),
    user: [],
    crmUserData: {},
  });

  const { setNotifications } = useContext(NotificationsContext);
  const { getCrmRequest } = useCrmCall();

  const { data = [], error: userDataError } = useQuery({
    queryKey: ['userData'],
    queryFn: () => getCrmRequest('/user/data'),
    enabled: userState?.isAuthenticated,
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  const {
    data: userBalance,
    error: balanceError,
    refetch,
  } = useQuery({
    queryKey: ['userBalance'],
    queryFn: () => getCrmRequest('/user/balance'),
    enabled: userState?.isAuthenticated,
    refetchOnWindowFocus: false,
    retryDelay: 300,
    refetchInterval: 30000,
  });

  useEffect(() => {
    setUserState(prev => {
      if (prev.crmUserData === data?.client) return prev;
      return { ...prev, crmUserData: data?.client };
    });
  }, [data]);

  useEffect(() => {
    const error = userDataError || balanceError;
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [balanceError, userDataError]);

  useEffect(() => {
    if (userBalance) {
      setUserState(prev => ({
        ...prev,
        crmUserData: {
          ...prev.crmUserData,
          wallets: userBalance,
        },
      }));
    }
  }, [userBalance]);

  const location = useLocation();

  useEffect(() => {
    if (userState.isAuthenticated) refetch();
  }, [location, userState.isAuthenticated]);

  const login = authInfo => {
    localStorage.setItem('authToken', authInfo?.token);
    setUserState({
      isAuthenticated: getIsAuthenticated(),
      user: authInfo?.user,
    });
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setUserState({
      isAuthenticated: false,
      user: [],
    });
  };

  return <UserContext.Provider value={{ userState, login, logout, setUserState }}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
